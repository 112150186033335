*{
    margin: 0;
    padding: 0;
   
}
:root {
  --main: #F12A02;
}

h1{
   
    font-size: 30px;
}

h2{

    font-size: 24px;
}

h3{
    font-size: 22px;
}

h4{
    font-size: 18px;
}
.domine{

    font-family: 'Domine', serif;
}

@font-face {
  font-family: 'MyCustomFont';
  src: url('./fonts/valky.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
  
.valky {
  font-family: 'MyCustomFont';
}
.parent-container {
  overflow: visible;
}
/* Ensure the dropdown menu has proper positioning and visibility */
.Menu.Items {
  overflow: visible; /* Ensure dropdown content is not clipped */
  position: absolute; /* Positioning to avoid layout shifts */
  z-index: 10; /* Ensure it appears above other content */
}

  
  
 
  


  
.rotating-curved-text {
    animation: rotate 20s linear infinite; /* Slower rotation */
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  